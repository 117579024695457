var columns = [{
  title: 'User ID',
  dataIndex: 'userid',
  key: 'userid',
  width: '18%',
  scopedSlots: {
    customRender: 'userid'
  },
  align: 'center'
}, {
  title: '用户名',
  dataIndex: 'username',
  key: 'username',
  width: '18%',
  scopedSlots: {
    customRender: 'username'
  },
  align: 'center'
}, {
  title: '推特ID',
  dataIndex: 'authorization',
  key: 'authorization',
  width: '12%',
  scopedSlots: {
    customRender: 'authorization'
  },
  align: 'center'
}, {
  title: '绑定状态',
  dataIndex: 'isdelete',
  key: 'isdelete',
  width: '12%',
  scopedSlots: {
    customRender: 'isdelete'
  },
  align: 'center'
}, {
  title: '绑定时间(UTC)',
  dataIndex: 'createdata',
  key: 'createdata',
  width: '15%',
  scopedSlots: {
    customRender: 'createdata'
  },
  align: 'center'
}, {
  title: '同步时间(UTC)',
  dataIndex: 'syncdata',
  key: 'syncdata',
  width: '15%',
  scopedSlots: {
    customRender: 'syncdata'
  },
  align: 'center'
}, {
  title: '操作',
  key: 'actions',
  dataIndex: 'actions',
  fixed: 'right',
  width: 150,
  scopedSlots: {
    customRender: 'actions'
  },
  align: 'center'
}];
export { columns };